import { useEffect, useState } from 'react';
import { Icon, Icons } from '../../../Components/Icon';
import Button from '../../../Components/Form/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectOnboarding } from '../../../selectors/onboarding-selector';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetOnboardingFormState } from '../../../reducers/onboarding.reducer';
import { useCreateBusiness } from '../../../hooks/queries-and-mutations/business';
import { logout } from '../../../thunks/account-thunk';
import { getBussiness, getPermission } from '../../../service/business';
import { setBusiness, setPermission } from '../../../reducers/business.reducer';

export default function Preview() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const business = useAppSelector(selectOnboarding);

  const navigate = useNavigate();

  useEffect(() => {
    if (!business) {
      toast.success(`You don't have access to this page`);
      dispatch(logout());
      dispatch(resetOnboardingFormState());
      navigate('/auth/login');
    }
  }, [business, dispatch, navigate]);

  const { mutate, isSuccess, isError } = useCreateBusiness();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('type', 'buyer_supplier');
    formData.append('email', business.email.toLocaleLowerCase());
    formData.append('name', business.name);
    formData.append('phone', business.phone);
    formData.append('industryType', business.industryType);
    formData.append('companySize', business.companySize);
    formData.append('rcNumber', business.rcNumber);
    const files = [
      business.businessFile!,
      business.utilityFile!,
      business.identificationFile!,
      business.licenseFile!,
    ];

    files.forEach((file) => {
      formData.append('kybDocument', file);
    });
    for (let i = 0; i < business.companyAddresss.length; i++) {
      const addressObj = business.companyAddresss[i];
      formData.append('addresses', JSON.stringify(addressObj));
    }

    formData.append('directorsDetails', JSON.stringify(business.directors));

    for (let i = 0; i < business.representatives.length; i++) {
      const addressObj = business.representatives[i];
      formData.append('repsDetails', JSON.stringify(addressObj));
    }
    for (let i = 0; i < business.banks.length; i++) {
      const bank = business.banks[i];
      const bankObj = {
        ...bank,
        bankName: bank.bank.label,
        bankCode: bank.bank.value,
        accountNumber: bank.accountNumber.toString(),
      };
      if ('bank' in bankObj) {
        delete (bankObj as { bank?: any }).bank;
      }
      formData.append('bankInfo', JSON.stringify(bankObj));
    }

    mutate(formData);
  };

  const getTheBusinessID = async () => {
    const business = await getBussiness();
    if (business.docs.length >= 1) {
      dispatch(setBusiness(business));
      const permission = await getPermission(business.docs[0].businessID._id);
      dispatch(setPermission(permission));
    } else {
      toast.error('Sorry!, An error has occurred while getting business details');
    }
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      toast.success('Business Registration Successful');
      setIsSubmitting(false);
      getTheBusinessID();
      dispatch(resetOnboardingFormState());
      // TODO: change to dashboard after dashboard page is live
      navigate('/dashboard');
    } else {
      setIsSubmitting(false);
    }
  }, [isError, isSuccess]);

  const handleGoBack = () => {
    navigate('/onboarding/kyb'); // Go back to the KYB page
  };
  return (
    <div>
      <div className="mb-8">
        <div className="border-b border-[#0000000D] pb-2 mb-11">
          <h2 className="text-xl text-[#263238] font-semibold">Detail Preview</h2>
          <p className="text-base font-normal font-inter text-[#00000066]">
            Verify the detail below
          </p>
        </div>
        <div className="pb-10 my-12 border-b">
          <div className="grid grid-cols-2 mb-5 gap-y-8 sm:grid-cols-4">
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-black/40 font-inter">
                Company Name
              </span>
              <span className="text-lg font-medium text-black font-inter">
                {business.name}
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-black/40 font-inter">
                Phone Number
              </span>
              <span className="text-lg font-medium text-black font-inter">
                {business.phone}
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-black/40 font-inter">
                RC Number
              </span>
              <span className="text-lg font-medium text-black font-inter">
                {business.rcNumber}
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-black/40 font-inter">
                Company Size
              </span>
              <span className="text-lg font-medium text-black font-inter">
                {business.companySize}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2 pt-5">
            <span className="text-sm font-medium text-black/40 font-inter">
              Industry Type
            </span>
            <span className="text-lg font-medium text-black font-inter">
              {business.industryType}
            </span>
          </div>
        </div>

        {business.companyAddresss.map((ad, index) => (
          <div className="pb-10 my-12 border-b" key={index}>
            <div className="grid grid-cols-2 mb-5 gap-y-8 sm:grid-cols-4">
              <div className="flex flex-col gap-2">
                <span className="text-sm font-medium text-black/40 font-inter">
                  Company Address
                </span>
                <span className="text-lg font-medium text-black font-inter">
                  {ad.address}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm font-medium text-black/40 font-inter">
                  Country
                </span>
                <span className="text-lg font-medium text-black font-inter">
                  {ad.country}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm font-medium text-black/40 font-inter">
                  State
                </span>
                <span className="text-lg font-medium text-black font-inter">
                  {ad.state}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-sm font-medium text-black/40 font-inter">
                  Postal Code
                </span>
                <span className="text-lg font-medium text-black font-inter">
                  {ad.postalCode}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-2 pt-5">
              <span className="text-sm font-medium text-black/40 font-inter">
                Address Type
              </span>
              <span className="text-lg font-medium text-black font-inter">
                {ad.addressType}
              </span>
            </div>
          </div>
        ))}

        <div className="pb-10 my-12 border-b">
          <div className="grid flex-1 grid-cols-2 mt-10 gap-y-8 sm:grid-cols-3">
            <div className="flex items-center gap-2">
              <Icon name={Icons.Attachment} />
              <p className="font-medium font-inter text-lg text-[#0353A4]">
                {business.businessFile?.name}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Icon name={Icons.Attachment} />
              <span className="font-medium font-inter text-lg text-[#0353A4]">
                {business.licenseFile?.name}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <Icon name={Icons.Attachment} />
              <span className="font-medium font-inter text-lg text-[#0353A4]">
                {business.identificationFile?.name}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <Icon name={Icons.Attachment} />
              <span className="font-medium font-inter text-lg text-[#0353A4]">
                {business.utilityFile?.name}
              </span>
            </div>
          </div>
        </div>

        {(business.directors ?? []).map((dirc, index) => {
          return (
            <div className="pb-10 my-12 border-b" key={index}>
              <div className="grid flex-1 grid-cols-1 mt-10 gap-y-8 sm:grid-cols-2">
                <div className="flex flex-col gap-2">
                  <p className="text-sm font-medium text-black/40">Director Fullname</p>
                  <span className="text-lg font-medium text-black font-inter">
                    {dirc.fullName}
                  </span>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-sm font-medium text-black/40">ID Number</p>
                  <span className="text-lg font-medium text-black font-inter">
                    {dirc.number}
                  </span>
                </div>
                {/* <div className='flex items-center gap-2'>
                  <Icon name={Icons.Attachment} />
                  <span className='font-medium font-inter text-lg text-[#0353A4]'>{dirc.attachId.name}</span>
                </div> */}
              </div>
            </div>
          );
        })}

        {(business.representatives ?? []).map((rep, index) => (
          <div className="pb-10 my-12 border-b" key={index}>
            <div className="grid flex-1 grid-cols-3 mt-10 gap-y-8 sm:grid-cols-3">
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium text-black/40">Reps First Name</p>
                <span className="text-lg font-medium text-black font-inter">
                  {rep.firstName}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium text-black/40">Reps Last Name</p>
                <span className="text-lg font-medium text-black font-inter">
                  {rep.lastName}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium text-black/40">Reps Email</p>
                <span className="text-lg font-medium text-black font-inter">
                  {rep.email}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium text-black/40">Reps Position</p>
                <span className="text-lg font-medium text-black font-inter">
                  {rep.position}
                </span>
              </div>
            </div>
          </div>
        ))}

        {(business.banks ?? []).map((bk, index) => (
          <div className="pb-10 my-12" key={index}>
            <div className="grid flex-1 grid-cols-2 mt-10 gap-y-8 sm:grid-cols-3">
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium text-black/40">Bank Name</p>
                <span className="text-lg font-medium text-black font-inter">
                  {bk.bank.label}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium text-black/40">Account Number</p>
                <span className="text-lg font-medium text-black font-inter">
                  {bk.accountNumber}
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm font-medium text-black/40">Account Name</p>
                <span className="text-lg font-medium text-black font-inter">
                  {bk.accountName}
                </span>
              </div>
            </div>
          </div>
        ))}
        <div className="flex items-center justify-between">
          <Button
            type="button"
            variant="outline"
            size="custom"
            label="Back"
            className="py-2.5 w-[8rem] mt-12 bg-transparent"
            onClick={handleGoBack}
          />
          <Button
            type="button"
            variant="primary"
            size="md"
            label="Save & Continue"
            className="mt-12"
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
}
