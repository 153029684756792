import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './Context/Context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { persistStore } from 'redux-persist';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { FlagProvider } from '@unleash/proxy-client-react';
import { unleashConfig } from './config';

const queryClient = new QueryClient();
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <FlagProvider config={unleashConfig}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            <AppProvider>
              <App />
            </AppProvider>
          </React.StrictMode>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </FlagProvider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
