import React from 'react';
import clsx from 'clsx';

type Props = {
  status: string;
  title: string;
  test?: string;
};

export default function Status({ status, title }: Props) {
  return (
    <div className="flex items-center gap-2">
      <span
        className={clsx({
          'mr-2 inline-flex items-center justify-center w-2.5 h-2.5 rounded-full': true,
          'bg-[#216509E8]': status === 'Active',
          'bg-[#FF2727] ': status === 'Blacklisted',
          'bg-[#FFC107] ': status === 'Pending',
          'bg-[#F48D14E8]': status === 'Unregistered',
          'bg-[#F48D14E8] ': status === 'Not registered',
        })}
      ></span>
      <span>{title}</span>
    </div>
  );
}
