import { useRef, useState } from 'react';
import hero1 from '../../Assests/landing-hero-1.png';
import hero2 from '../../Assests/landing-hero-2.png';
import hero3 from '../../Assests/landing-hero-3.png';
import slide1 from '../../Assests/slide-1.png';
import mackbook from '../../Assests/MacBook Pro 16.png';
import logo from '../../Assests/logo-light.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  Swiper as SwiperType,
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from 'swiper';
import { Icon, Icons } from '../../Components/Icon';
import { Link, useNavigate } from 'react-router-dom';
import withCreatePortal from '../../Components/Hoc/withCreatePortal';
import EarlyAccess from '../../Components/Modal/EarlyAccess';
import PricingMobile from './PricingMobile';
import { useResize } from '../../hooks/useResize';
import { faqs, slides } from '../../Data/constant';

const EnhancedEarlyAccessModal = withCreatePortal(EarlyAccess);

export default function Landing() {
  const navigate = useNavigate();
  const swiperRef = useRef<SwiperType>();
  const [activeSlide, setActiveSlide] = useState(1);
  const [selectedFaq, setSelectedFaq] = useState<number | null>(null);
  const [showEarlyAccess, setShowEarlyAccess] = useState(false);
  const [visibleFaqs, setVisibleFaqs] = useState<number>(5);

  const handleLoadMore = () => {
    setVisibleFaqs((prevVisibleFaqs) => prevVisibleFaqs + 5);
  };

  const width = useResize();

  return (
    <div className="mb-20s">
      {/* Hero */}
      <div className="relative overflow-hidden">
        <section className="relative overflow-hidden rounded-[20px] bg-[#E1EBF4]">
          <div className="container relative">
            <div className="text-center">
              <div className="flex justify-center mt-20">
                <div className="max-w-xl">
                  <h1 className="mb-4 font-medium text-gray-800 text-4xl/tight">
                    The collaboration tool for procurement and finance teams
                  </h1>
                  <p className="mx-auto text-base text-black/50 lg:max-w-md">
                    Manage vendors, invoicing and payments in one place
                  </p>
                </div>
              </div>
              <div>
                <button
                  onClick={() => navigate('/auth/create-account')}
                  className="relative flex items-center justify-center rounded-[9.79px] text-base font-medium py-3 px-10 mt-10 mx-auto bg-[#061A40] text-white"
                >
                  Get started
                </button>
              </div>
            </div>
          </div>
          <div className="relative z-10 max-w-5xl px-6 mx-auto mt-16">
            <div className="hidden lg:block">
              <img
                src={hero2}
                alt="Top Left"
                className="absolute mt-10 top-16 -left-48"
              />
              <img
                src={hero3}
                alt="Bottom Right"
                className="absolute bottom-0 -right-48 mb-14"
              />
            </div>
            <img src={hero1} className="w-full h-full rounded-md" alt="hero1" />
          </div>
        </section>
      </div>

      {/* About */}
      <div
        className="container grid grid-cols-1 mt-20 px-28 lg:grid-cols-2 sm:grid-cols-2 sm:gap-8 lg:gap-32 md:gap-32"
        id="about"
      >
        <div className="flex flex-col gap-3 sw-2/5">
          <p className="text-lg font-medium text-black/50">About Paytton</p>
          <p className="text-4xl font-normal text-black">
            Paytton enhances collaboration and work efficiency for procurement and finance
            professionals.
          </p>
        </div>
        <div className="sw-[18%] mt-10">
          <p className="text-xl font-medium text-black/60">
            Streamline your source-to-pay process and revolutionize the way your teams
            work together.
          </p>
        </div>
      </div>

      {/* Feature 1 */}
      <div className="mt-20" id="feature">
        <div className="rounded-[20px] bg-[#B9D6F224] pt-10 my-14">
          <div className="w-3/5s mx-96s px-9 space-x-12s">
            <div className="flex items-center justify-between">
              <p className="font-medium text-xl text-[#263238]">With Paytton, you can:</p>
              <div className="swiper-pagination" />
              <div className="flex items-center gap-2 swiper-paginations">
                <div
                  role="button"
                  tabIndex={0}
                  className={`p-1 w-8 ${
                    activeSlide === 1 ? 'bg-[#0353A4]' : 'bg-[#D9D9D9]'
                  } rounded-[2px] cursor-pointer`}
                  onClick={() => {
                    swiperRef.current?.slideNext();
                    setActiveSlide(2);
                  }}
                ></div>
                <div
                  role="button"
                  tabIndex={0}
                  className={`p-1 w-8 ${
                    activeSlide === 2 ? 'bg-[#0353A4]' : 'bg-[#D9D9D9]'
                  } rounded-[2px] cursor-pointer`}
                  onClick={() => {
                    swiperRef.current?.slideNext();
                    setActiveSlide(3);
                  }}
                ></div>
                <div
                  role="button"
                  tabIndex={0}
                  className={`p-1 w-8 ${
                    activeSlide === 3 ? 'bg-[#0353A4]' : 'bg-[#D9D9D9]'
                  } rounded-[2px] cursor-pointer`}
                  onClick={() => {
                    swiperRef.current?.slideNext();
                    setActiveSlide(1);
                  }}
                ></div>
              </div>
            </div>
            <Swiper
              spaceBetween={3}
              slidesPerView={width > 640 ? 3 : 1}
              // loop={true}
              slidesPerGroup={1}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              scrollbar={{ draggable: true }}
              modules={[Autoplay, Pagination, Navigation, Scrollbar]}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              onSlideChange={(swiper) => {
                if (swiper.activeIndex === 0) {
                  return setActiveSlide(1);
                }
                if (swiper.activeIndex === 1) {
                  return setActiveSlide(2);
                }
                if (swiper.activeIndex === 2) {
                  return setActiveSlide(3);
                }
              }}
              className="pt-10"
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div
                    className={`flex flex-col ${slide.bodyClass} rounded-tr-xl rounded-tl-xl md:w-5/6 lg:w-5/6`}
                  >
                    <div className="left-0 w-full p-3 my-5 text-center text-white absolutes">
                      <h1
                        className={`text-lg ${slide.textClass} font-normal whitespace-pre-line`}
                        dangerouslySetInnerHTML={{ __html: slide.title }}
                      ></h1>
                    </div>
                    <img
                      src={slide.image}
                      alt="Slide 1"
                      className={`h-44 ${slide.imageClass}`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      {/* Feature 2 */}
      <div className="container my-20s px-28s">
        <div className="flex flex-col items-center justify-center text-center mb-14">
          <p className="text-4xl font-medium text-black">
            Built with love for procurement and finance teams
          </p>
          <span className="mt-3 text-lg font-normal text-black">
            Features that mirror your procurement workflow
          </span>
        </div>
        <div className="w-full mt-10">
          <div>
            <div className="bg-[#E1EBF4] py-20 pl-10 pr-5 rounded-2xl">
              <p className="text-2xl">Vendor Management</p>

              <div className="grid items-center grid-cols-1 gap-6 md:grid-cols-2">
                <div className="flex justify-between">
                  <div className="mt-10 h-4/5 left-10 absolutes">
                    <div className="w-52 absolute origin-top-left rotate-90 rounded border-2 border-[#00000014] border-opacity-10"></div>
                    <div className="w-20 absolute origin-top-left rounded rotate-90 border-2 border-[#0353A4]"></div>
                  </div>
                  <div className="flex flex-col mt-8 ml-10">
                    <p className="mb-6 text-lg font-medium text-black">
                      Automated vendor onboarding
                    </p>
                    <span className="text-sm font-normal leading-4 mb-10 w-[45%]">
                      Save time and ensure thorough vendor vetting with Paytton's
                      automated onboarding process.
                    </span>
                    <div className="space-y-2">
                      <p className="text-lg font-medium text-black/50">
                        Vendor performance tracking
                      </p>
                      <p className="text-lg font-medium text-black/50">
                        Automated vendor payments and approvals
                      </p>
                    </div>
                  </div>
                </div>
                <div className="relative z-10 w-full h-full px-6 mr-20 max-w-5xls mx-autos mt-16s">
                  <div className="hidden lg:block">
                    <img
                      src={slide1}
                      alt="Top Right"
                      className="absolute -top-10 -right-[20px] rounded w-52 mr-10"
                    />
                  </div>
                  <img src={mackbook} className="w-full h-full rounded-md" alt="hero1" />
                </div>
              </div>
              {/* <div className='flex justify-between mt-10'>
                <div className='flex justify-between'>
                  <div className="mt-10 h-4/5 left-10 absolutes">
                    <div className="w-52 absolute origin-top-left rotate-90 rounded border-2 border-[#00000014] border-opacity-10"></div>
                    <div className="w-20 absolute origin-top-left rounded rotate-90 border-2 border-[#0353A4]"></div>
                  </div>
                  <div className='flex flex-col mt-8 ml-10'>
                    <p className='mb-6 text-lg font-medium text-black'>Automated vendor onboarding</p>
                    <span className='text-sm font-normal leading-4 mb-10 w-[45%]'>Save time and ensure thorough vendor vetting with Paytton's automated onboarding process.</span>
                    <div className='space-y-2'>
                      <p className='text-lg font-medium text-black/50'>Vendor performance tracking</p>
                      <p className='text-lg font-medium text-black/50'>Automated vendor payments and approvals</p>
                    </div>
                  </div>
                </div>
                <div className="relative z-10 max-w-5xl px-6 mr-20 mx-autos mt-16s">
                  <div className="hidden lg:block">
                    <img
                      src={slide1}
                      alt="Top Right"
                      className="absolute bottom-32 -right-[20px] mb-14 rounded"
                    />
                  </div>
                  <img src={mackbook} className="w-full h-full rounded-md" alt="hero1" />
                </div>
              </div> */}
            </div>
            <div className="absolute ml-4 text-sm font-normal text-black -mt-9">01</div>
          </div>
        </div>
      </div>

      {/* Pricing */}
      <div id="pricing">
        <div className="hidden w-full mt-20 lg:block md:block">
          <div className="relative md:p-20 md:pb-52 p-10 bg-[#0B58A71C] bg-opacity-0">
            <div className="">
              <div className="flex flex-col items-center justify-center text-center mb-14">
                <p className="text-4xl font-medium text-black">
                  Supercharge your source-to-pay efficiency
                </p>
                <span className="text-lg font-normal text-black">
                  Get early access and see how Paytton can help you save time, money, and
                  improve compliance
                </span>
              </div>
              <div className="relative mt-10">
                <div className="grid grid-cols-1 gap-10 py-6 lg:grid-cols-3 md:grid-cols-2">
                  <div className="sw-[78%]">
                    <div className="relatives bg-white rounded-[20px] h-full">
                      <div className="p-6">
                        <Icon name={Icons.People} />
                      </div>
                      <div className="px-6 pb-6">
                        <h3 className="text-lg font-medium text-gray-800 mb-0.5">
                          Efficiency and
                          <br />
                          Seamless Collaboration
                        </h3>
                        <p className="mt-3 text-sm font-normal text-gray-500">
                          Empower your procurement and finance teams to work in perfect
                          harmony with Paytton's intuitive collaboration features. Say
                          goodbye to silos and embrace unified workflows.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="sw-[78%]">
                    <div className="relatives bg-white rounded-[20px] h-full">
                      <div className="p-6">
                        <Icon name={Icons.HandCoin} />
                      </div>
                      <div className="px-6 pb-6">
                        <h3 className="text-lg font-medium text-gray-800 mb-0.5">
                          Savings and Optimized
                          <br />
                          Financial Decisions
                        </h3>
                        <p className="mt-3 text-sm font-normal text-gray-500">
                          Unlock significant cost savings and optimize financial decisions
                          with real-time spending visibility. Make informed choices that
                          impact your bottom line positively.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="sw-[78%]">
                    <div className="relatives bg-white rounded-[20px] h-full">
                      <div className="p-6">
                        <Icon name={Icons.Growth} />
                      </div>
                      <div className="px-6 pb-6">
                        <h3 className="text-lg font-medium text-gray-800 mb-0.5">
                          Enhanced Compliance
                          <br /> and Risk Mitigation
                        </h3>
                        <p className="mt-3 text-sm font-normal text-gray-500">
                          Centralize vendor data and approvals within Paytton, reducing
                          the risk of errors and ensuring compliance with regulations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute -bottom-[28%] w-[90%] flex items-center justify-between px-14 pt-10 bg-[#0353A4] bg-opacity-90 rounded-2xl">
              <div className="w-[35%] flex flex-col -mt-8">
                <div className="space-y-3">
                  <p className="text-4xl font-semibold text-white">
                    One tool for your source-to-pay operations
                  </p>
                  <p className="text-lg font-normal text-white">Free for teams to try.</p>
                </div>
                <button
                  onClick={() => navigate('/auth/create-account')}
                  className="rounded-[9.79px] w-[10rem] text-base font-medium p-3 mt-10 bg-[#061A40] text-white"
                >
                  Get started
                </button>
              </div>
              <div className="relative w-1/4 overflow-hidden shadow-md bg-white/50 rounded-tl-xl rounded-tr-xl">
                <div className="flex flex-col h-full p-6">
                  <div className="shrink">
                    <h5 className="mb-2 text-xl font-semibold text-black">Standard</h5>
                    <div className="my-3">
                      <h3 className="flex items-center gap-2 text-2xl font-bold text-black">
                        <span>$5</span>
                        <span className="text-lg font-medium">/ seat</span>
                        <span className="text-lg font-medium">/ month</span>
                      </h3>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p className="text-sm font-medium text-gray-700">
                      Enjoy these features
                    </p>
                  </div>
                  <ul className="mt-5 grow">
                    <li className="flex items-center gap-2 mb-4 text-sm font-normal">
                      <Icon name={Icons.Mark} />
                      <span className="ml-2">Vendor management </span>
                    </li>
                    <li className="flex items-center gap-2 mb-4 text-sm font-normal">
                      <Icon name={Icons.Mark} />
                      <span className="ml-2">Invoicing</span>
                    </li>
                    <li className="flex items-center gap-2 mb-4 text-sm font-normal">
                      <Icon name={Icons.Mark} />
                      <span className="ml-2">Payments</span>
                    </li>
                  </ul>
                  <div className="mt-3 text-sm font-medium shrink text-black/75">
                    <p>You get 14 days to try it out, no commitment</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pricing Mobile */}
        <div className="mt-10 lg:hidden md:hidden">
          <PricingMobile />
        </div>
      </div>

      {/* FAQ */}
      <div
        className="relative flex flex-col items-center lg:mt-[20rem] md:mt-[20rem] mt-32 container"
        id="faq"
      >
        <div className="flex items-center justify-center">
          <div className="max-w-2xl text-center">
            <h2 className="mb-1 font-bold text-gray-800 text-3xl/snug">
              Frequently asked questions
            </h2>
          </div>
        </div>
        <div className="w-full mt-14">
          <div className="mx-auto max-w-5xls">
            <div className="space-y-4 hs-accordion-group">
              {faqs.slice(0, visibleFaqs).map((faq) => (
                <div
                  className="hs-accordion active bg-[#0353A40F] rounded-lg overflow-hidden py-1"
                  key={faq.id}
                >
                  <button
                    className="inline-flex items-center justify-between w-full px-6 py-4 text-left transition-all hs-accordion-toggle gap-x-3 text-gray-950"
                    onClick={() => setSelectedFaq(selectedFaq === faq.id ? null : faq.id)}
                  >
                    <h5 className="text-base font-medium">
                      <i className="me-2" />
                      {faq.name}
                    </h5>
                    <div
                      className={`h-4 w-4 transition-all duration-500 ${
                        selectedFaq === faq.id ? 'rotate-180' : ''
                      }`}
                    >
                      <Icon name={Icons.CaretDown} />
                    </div>
                  </button>
                  {faq.id === selectedFaq && (
                    <div className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300">
                      <div className="pt-0 pb-4 px-9">
                        <p className="mb-2 text-sm font-medium text-gray-600">
                          {faq.desc}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {visibleFaqs < faqs.length && (
          <button
            type="button"
            className="py-2 px-10 mt-16 rounded-lg border text-base font-medium border-[#0353A4] text-[#0353A4]"
            onClick={handleLoadMore}
          >
            Load more
          </button>
        )}
      </div>

      {/* Footer */}
      <footer className="bg-[#0353A4] rounded-tl-[20px] rounded-tr-[20px] md:mt-40 lg:mt-40 mt-20">
        <div className="container lg:px-20">
          <div className="flex flex-col justify-between py-24 lg:flex-row gap-14">
            <div className="lg:w-2/4">
              <div className="grid gap-6 md:grid-cols-3 sm:grid-cols-2">
                <div className="">
                  <ul className="flex flex-col gap-3">
                    <h5 className="mb-2 font-semibold text-white xl:text-xl lg:text-lg">
                      Features
                    </h5>
                    <li>
                      <Link
                        to=""
                        className="text-sm font-medium transition-all text-white/60 hover:text-white"
                      >
                        Vendor Management
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        className="text-sm font-medium transition-all text-white/60 hover:text-white"
                      >
                        Invoicing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        className="text-sm font-medium transition-all text-white/60 hover:text-white"
                      >
                        Payments
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        className="text-sm font-medium transition-all text-white/60 hover:text-white"
                      >
                        Approval workflows
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="">
                  <ul className="flex flex-col gap-3">
                    <h5 className="mb-2 font-semibold text-white xl:text-xl lg:text-lg">
                      Industries
                    </h5>
                    <li>
                      <Link
                        to=""
                        className="text-sm font-medium transition-all text-white/60 hover:text-white"
                      >
                        FMCG
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        className="text-sm font-medium transition-all text-white/60 hover:text-white"
                      >
                        Trade
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        className="text-sm font-medium transition-all text-white/60 hover:text-white"
                      >
                        Manufacturing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        className="text-sm font-medium transition-all text-white/60 hover:text-white"
                      >
                        Services
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="">
                  <ul className="flex flex-col gap-3">
                    <h5 className="mb-2 font-semibold text-white xl:text-xl lg:text-lg">
                      Company
                    </h5>
                    <li>
                      <Link
                        to=""
                        className="text-sm font-medium transition-all text-white/60 hover:text-white"
                      >
                        Terms of Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        to=""
                        className="text-sm font-medium transition-all text-white/60 hover:text-white"
                      >
                        Privacy policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="lg:w-3/12">
              <p className="text-white text-2xl font-medium max-w-[17rem] mt-6">
                Are you ready to accelerate your business
              </p>
              <button className="rounded-[9.79px] w-[9rem] text-base font-medium p-2.5 mt-10 bg-[#061A40] text-white">
                Contact us
              </button>
            </div>
          </div>

          <div className="border-t border-white/20 md:h-[75px] lg:h-[75px] h-36">
            <div className="grid items-center h-full grid-cols-1 gap-3 md:grid-cols-3 sm:grid-cols-2 text-centers md:text-start">
              <img src={logo} className="w-36" alt="" />
              <p className="ml-3 text-sm font-normal text-white">
                ©{new Date().getFullYear()} Paytton. All Right Reserved
              </p>
              <ul className="flex items-center gap-2 flex-wraps opacity-30">
                <li>
                  <Link
                    to=""
                    className="inline-flex items-center justify-center w-10 h-10"
                  >
                    <Icon name={Icons.Twitter} />
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    className="inline-flex items-center justify-center w-10 h-10"
                  >
                    <Icon name={Icons.Instagram} />
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    className="inline-flex items-center justify-center w-10 h-10"
                  >
                    <Icon name={Icons.LinkedIn} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Container End */}
      </footer>

      {showEarlyAccess && (
        <EnhancedEarlyAccessModal
          onCancel={() => setShowEarlyAccess(false)}
          onConfirm={() => setShowEarlyAccess(false)}
        />
      )}
    </div>
  );
}
